<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'ChatroomIndex',
              }"
              >聊天室管理</b-breadcrumb-item
            >
            <b-breadcrumb-item
              :to="{
                name: 'ChatroomList',
              }"
              >聊天室列表</b-breadcrumb-item
            >
            <b-breadcrumb-item active>聊天室成員列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-2 mb-xl-2 justify-content-between">
        <div class="col-6 d-flex align-items-center">
          <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">聊天室成員列表</h4>
        </div>
      </div>

      <b-table
        striped
        hover
        responsive
        :items="senders"
        :fields="fields"
        :busy="isLoading"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>

      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-pagination
          class="separated"
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="senderList"
          @change="(page) => fetchSenders(page)"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import chatroomApi from "../../../apis/chatroomManagement";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";

export default {
  data() {
    return {
      consts,
      isLoading: false,
      senders: [],
      fields: [
        {
          key: "name",
          label: "名稱",
        },
        {
          key: "type",
          label: "成員類型",
        },

        {
          key: "is_enabled",
          label: "是否啟用",
        },
        {
          key: "read_at",
          label: "已讀時間",
        },
        {
          key: "notify_at",
          label: "通知時間",
        },
      ],
      perPage: 15,
      currentPage: 1,
      total: 0,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    chatroomId() {
      return this.$route.params.chatroomId;
    },
  },
  mounted() {
    this.fetchSenders();
  },
  methods: {
    async fetchSenders(page = 1) {
      try {
        this.isLoading = true;
        const { data } = await chatroomApi.getSenders(this.chatroomId);
        this.total = data.data.length;
        const start = (page - 1) * this.perPage;
        const end = page * this.perPage;
        this.senders = Array.isArray(data.data)
          ? data.data.slice(start, end)
          : [];
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
  },
};
</script>
