import https from "./https";
import store from "@/store";

const chatrooms = {
  getChatrooms(params) {
    const organization = store.state.general.organization;
    return https.get(`admin/manage/organizations/${organization.id}/chatrooms`, {
      params,
    });
  },

  getChatroom(chatroomId) {
    return https.get(`admin/manage/chatrooms/${chatroomId}`);
  },

  getMessages({ chatroomId, page, count }) {
    return https.get(
      `admin/manage/chatrooms/${chatroomId}/messages?page=${page}&count=${count}`
    );
  },

  createChatroom(chatroomData) {
    const organization = store.state.general.organization;
    return https.post(
      `admin/manage/organizations/${organization.id}/chatrooms`,
      chatroomData
    );
  },

  updateChatroom(chatroomId, chatroomData) {
    return https.put(`admin/manage/chatrooms/${chatroomId}`, chatroomData);
  },

  deleteChatroom(chatroomId) {
    return https.delete(`admin/manage/chatrooms/${chatroomId}`);
  },

  getSenders(chatroomId) {
    return https.get(`admin/manage/chatrooms/${chatroomId}/senders`);
  },
};

export default chatrooms;
